@media (min-width: 500px ) and  (max-width: 992px ) {
    .mobileContainer{
        display: block;
    }
    .wrap{
        width: 100%;
        padding: 16px;
    }
    .navButtons{
        font-size: 22px;
    }
    .photoInfo{
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("infoItem1.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 600px;
        width: 95%;
    }
    .logo{
        width: 200px;
        height: 50px;
    }
    .textApp{
        color: white;
        font-size: 24px;
        width: 95vw;
        text-align: center;
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .textMuchMore{
        position: relative;
        margin-top: 0;
        font-size: 25px;
        font-weight: bold;
    }
    .safetyItemsCloud {
        width: 500px;
        height: 300px;
        padding: 16px 16px;
    }
    .textSafetyCloud{
        font-size: 20px;
    }
    .titleSafetyCloud{
        font-size: 25px;
    }
    .text{
        margin-top: 32px;
        margin-bottom: 0;
        font-size: 40px;
        text-align: center;
        align-self: center;
    }
    .circleContainer{
        /*width: 90%;*/
        /*height: 80vh;*/
        height: 500px;
        width: 95%;
    }
    .download-buttons-container{
        margin-top:24px;
        margin-bottom: 16px;
        align-items: center;
        justify-content: center;
    }
    .appleButton{
        min-width: 188px;
        min-height: 59px;
    }
    .playButton{
        min-width: 188px;
        min-height: 59px;
    }
    .containerIcons{
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.3rem;
    }
    .textTerms{
        font-size: 0.7rem;
        width: 100%;
    }
    .footer{
        padding: 0;
        min-height: 5vh;
        background-color: #101728;  }
}
