@media (min-width: 1200px)  {
    .mobileContainer{
        display: none;
    }
    .desktopContainer{
        display: block;

    }
    .wrapDesktop{
        align-items: flex-start;
        justify-content: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 16px 0 16px 10%;
    }

    .wrapDesktopContainer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-left: 0;
    }
    .download-buttons-containerDesktop {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        /*margin-top: 100px;*/
        background: linear-gradient(90deg, #ff66c4 0%, #ffde59 100%);
        width: 350px;
        height: 80px;
        background-size: cover;
        border-radius: 20px;
        padding: 0 8px 0 0;
        margin-top:24px;
        margin-bottom: 16px;
    }
    .playStoreButtonTextDesktop{
        text-decoration: none;
        font-size: 20px;
        font-weight: 500;
    }
    .playStoreButtonDesktop{
        width: 100px;
        height: 70px;
        background-image: url("playButton.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .navDesktop{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 16px 10%;
        justify-content: space-between;
    }
    .logoDesktop{
        width: 230px;
        height: 90px;
        background-image: url("logo.png");
        background-size: contain;
        background-repeat: no-repeat;
        filter: brightness(0) invert(1);
    }
    .navButtonsDesktop{
        color: white;
        font-size: 32px;
        font-weight: 400;
    }
    .contactButton{
        width: 140px;
        height: 50px;
        background-color: white;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .navButtonsContainerDesktop{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
    }
    .contactContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 32px;
    }
    .iconsDesktop{
        width: 45px;
        height: 45px;
        fill: white;
    }
    .circleContainerDesktop{
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("ellieDesktop.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 32px;
        height: 600px;
        width: 100%;
    }
    .textDesktop{
        margin-top: 32px;
        margin-bottom: 0;
        font-size: 75px;
        width: 95%;
        color: white;
        font-weight: bold;
    }
    .textAppDesktop{
        color: white;
        font-size: 30px;
        margin-top: 16px;
        margin-bottom: 16px;
        width: 75%;
    }
    .sectionContainerDesktop{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 16px 0 16px 0;

    }
    .infoContainerDesktop{
        margin-top: 16px;
        display: flex;
        align-items: flex-start;
        gap: 8px;
        padding: 16px 0 16px 10%;
        justify-content: flex-start;
        width: 100vw;
    }
    .photoInfoDesktop{
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("photoInfoDesktop.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 600px;
        width: 100%;
        max-width: 1000px;
        margin-right: 40px;
    }
    .infoContainerRowDesktop{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .backgroundsElements{
        width: 60%;
        height: 150px;
        background-image: url("backgroundElements.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        align-self: flex-start;
    }
    .infoItemContainerDesktop{
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: row;
        gap: 16px;
        margin-top: 32px;

    }
    .infoItemTextDesktop{
        color: white;
        font-size: 26px;
        width: 80%;
    }
    .photoInfo2Desktop{
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("photoInfo2Desktop.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 560px;
        width: 100%;
        margin-left: 116px;
    }
    .diversityContainerDesktop{
        position: absolute;
        top:10%;
        left: 45%;
        z-index: 100;
        transform: translateX(-100%);
        overflow: hidden;
    }
    .diversityDesktop{
        width: 180px;
        height: 90px;
        background-image: url("diversity.png");
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 110;
    }
    .beYourselfContainerDesktop{
        position: absolute;
        top:40%;
        right: 50%;
        z-index: 100;
        transform: translateX(100%);
        overflow: hidden;

    }
    .beYourselfDesktop{
        width: 180px;
        height: 90px;
        background-image: url("beYourself.png");
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 100;
    }
    .infoContainerDesktopSafety{
        display: flex;
        width: 100%;
        align-items: flex-start;
        flex-direction: column;
        gap: 16px;
        margin-top: 32px;
        padding: 16px 0 16px 10%;
    }
    .safetyItemsDesktop{
        background-color: white;
        width: 70%;
        height: auto;
        padding: 16px;
        border-radius: 36px;
        margin-bottom: 16px;
        box-shadow: 5px 5px #bababa;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        grid-area: auto;
    }
    .titleSafetyDesktop{
        font-size: 28px;
        font-weight: 500;
        opacity: 87%;
    }
    .textSafetyDesktop {
        opacity: 60%;
        font-weight: 400;
        text-align: center;
        font-size: 24px;
        margin-top: 8px;
    }
    .containerItemsSafety{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr ;
        grid-template-rows: 1fr 1fr ;
        gap: 64px 0px;
        grid-template-areas:
    ". . "
    ". . "
    ;
    }
    .safetyItemsCloudDesktop{
        width: 400px;
        height: 260px;
        background-image: url("cloud2.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 16px 32px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .titleSafetyCloudDesktop{
        font-size: 24px;
        font-weight: 500;
        opacity: 87%;
        text-align: center;
    }
    .textSafetyCloudDesktop{
        opacity: 60%;
        font-weight: 400;
        text-align: center;
        margin-top: 8px;
        font-size: 20px;
    }
    .containerCloudSafety{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr ;
        grid-template-rows: 1fr 1fr ;
        gap: 64px ;
        grid-template-areas:
    ". . "
    ". . "
    ;
    }
    .footerDesktop{
        width: 100vw;
        bottom: 0;
        min-height: 5vh;
        /*background-color: black;*/
        background-color: #101728;
        left: 0;
        display: flex;
        align-items: center;
        padding: 16px 0 16px 10%;

    }
    .containerTermsFooterDesktop{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        padding: 16px 0 16px 0;

    }
    .textTermsDesktop{
        color: white;
        width: 100%;
        font-size: 20px;
    }
}
