body{
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Rubik', sans-serif;
}
*, * ::before, *::after {
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.mobileContainer{
  display: none;
}
.desktopContainer{
  display: none;
}
.container{
  width: 100vw;
  min-height: 95vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(90deg, #5170ff 0%, #ff66c4 100%);
  overflow-x: hidden;
  padding: 0;
  justify-content: flex-start;
}
.text{
  margin-top: 32px;
  margin-bottom: 0;
  font-size: 40px;
  width: 95%;
  text-align: center;
  align-self: center;
}
.nav{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  padding: 16px;
}
.navButtonsContainer{
  display: flex;
  flex-direction: row;
  width: 60%;
  gap: 24px;
  justify-content: flex-end;
}
.navButtons{
  color: white;
  font-size: 18px;
  font-weight: 400;
}
.hrefContainerButtons{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-decoration: none;
  color: white;
}
.download-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /*margin-top: 100px;*/
  background: linear-gradient(90deg, #ff66c4 0%, #ffde59 100%);
  width: 260px;
  height: 68px;
  background-size: cover;
  border-radius: 38px;
  padding: 0 8px 0 0;
  margin-top:24px;
  margin-bottom: 16px;

}
.playStoreButtonText{
  text-decoration: none;
  font-size: 16px;
  margin-right: 4px;
  font-weight: 500;
}
/*.appleButton{*/
/*  background-image: url("appStore.png");*/
/*  width: 235px;*/
/*  height: 74px;*/
/*  background-size: cover;*/
/*  background-repeat: no-repeat;*/
/*  border-radius: 1rem;*/
/*  background-position: center;*/
/*}*/
.wrap{
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
}
.logo{
  width: 200px;
  height: 50px;
  background-image: url("logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  filter: brightness(0) invert(1);
}
.text{
  font-weight: bold;
  font-size: 40px;
  width: 95%;
  margin-top: 32px;
  margin-bottom: 8px;
  color: white;
  text-align: center;
  align-self: center;
}
.textMuchMore{
  background: -webkit-linear-gradient(90deg, #fff7ad, #ffa9f9 );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 25px;
  font-weight: bold;
}
.textApp{
  color: white;
  font-size: 18px;
  width: 95vw;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
}
.circleContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("ellieMain.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 16px;
  height: 500px;
  width: 95%;
}
.sectionContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.check{
  width: 10%;
  height: 40px;
  background-image: url("check.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.infoContainer{
  width: 100%;
  padding: 0 8px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;

}
.animationContainer{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
.diversityContainer{
  position: absolute;
  top:10%;
  left: 50%;
  z-index: 100;
  transform: translateX(-100%);
}
.diversity{
  width: 180px;
  height: 68px;
  background-image: url("diversity.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 110;
}
.beYourselfContainer{
  position: absolute;
  top:40%;
  right: 50%;
  z-index: 100;
  transform: translateX(100%);
}
.beYourself{
  width: 180px;
  height: 68px;
  background-image: url("beYourself.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 100;
}
.photoInfo{
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("infoItem1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;
  width: 95%;
}
.photoInfo2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("infoItem2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 560px;
  width: 95%;
}
.infoItemContainer{
  display: flex;
  width: 100%;
  padding: 0 8px;
  align-items: center;
  flex-direction: row;
  gap: 16px;
}
.infoItemText{
  color: white;
  font-size: 18px;
  width: 90%;
}
.safetyItems {
  background-color: white;
  width: 95%;
  height: auto;
  padding: 16px;
  border-radius: 36px;
  margin-bottom: 16px;
  box-shadow: 5px 5px #bababa;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.safetyItemsCloud {
  width: 360px;
  height: 200px;
  background-image: url("cloud2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 16px 32px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.titleSafety{
  font-size: 22px;
  font-weight: 500;
  opacity: 87%;
}
.textSafety{
  opacity: 60%;
  font-weight: 400;
}
.titleSafetyCloud{
  font-size: 22px;
  font-weight: 500;
  opacity: 87%;
  text-align: center;
  width: 80%;
}
.textSafetyCloud{
  opacity: 60%;
  font-weight: 400;
  text-align: center;
  width: 80%;
  margin-top: 8px;
}
.footer{
  width: 100vw;
  bottom: 0;
  min-height: 5vh;
  /*background-color: black;*/
  background-color: #101728;
  left: 0;
  display: flex;
  align-items: center;
  padding: 0;
}
.containerIcons{
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: center;
  gap: 1.3rem;
}
.icons{
  width: 25px;
  height: 25px;
  fill: white;
}
.playStoreButton{
  width: 65px;
  height: 65px;
  background-image: url("playButton.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.textTerms{
  color: white;
  width: 100%;
  font-size: 14px;
}
.logoTerms{
  width: 200px;
  height: 50px;
  background-image: url("logo.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.containerTerms{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
}
.containerTermsFooter{
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: center;
  padding: 16px;
  gap: 4px
}
.textTermsP{
  text-align: justify;
  overflow-x: visible;
}
.textTermsPbold{
  font-weight: bold;
}
.textTermsTitle{
  color: #421d4b;
}
.spanBold{
  font-weight: bold;
}
.hrTerms{
  background-color: black;
  width: 100%;
  height: 1px;
  margin: 24px 0;
}

.divTable{
  display: table;
  width: 100%;
  border: 1px solid #000;
  table-layout: fixed;
}
.divTableRow {
  display: table-row;
  text-align: justify;
}
.divTableRowFirst{
  display: table-row;
  background-color: rgba(191, 122, 240, 0.25);
  font-weight: bold;
  text-align: center;
}
.divTableHeading {
  display: table-header-group;
}
.divTableCell, .divTableHead {
  border: 1px solid #999999;
  display: table-cell;
  padding: 3px 10px;
}
.divTableHeading {
  background-color: #EEE;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
}



@media (max-width: 340px ) {
  .mobileContainer{
    display: block;
  }
  .titleSafetyCloud{
    font-size: 18px;

  }
}
@media (max-width: 500px ) and (min-width: 340px){
  .mobileContainer{
    display: block;
  }
  .textSafetyCloud{
    font-size: 15px;
  }
  /*.diversity{*/
  /*  margin-bottom: 80%;*/
  /*  left: 40px;*/
  /*}*/
  .beYourself{
    right: 0px;
  }
}
@media (max-width: 992px ) {

}

@media (max-width: 1700px)and (min-width: 1200px) {
  .text{
    font-size: 60px;
  }

}

/*@media (max-width: 992px ) {*/
/*  .text{*/
/*    font-size: 36px;*/
/*  }*/
/*  .logo{*/
/*    width: 220px;*/
/*    height: 70px*/
/*  }*/
/*  .container{*/
/*    padding:30px;*/
/*  }*/
/*  .wrap{*/
/*    padding-left: 60px;*/
/*  }*/
/*}*/
